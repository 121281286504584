import React from "react"
import { Link, graphql } from "gatsby"
import PageWrapper from "../../components/PageWrapper"
import SEO from "../../components/seo"
import { Col, Container, Row, Button } from "react-bootstrap"
import NavBarSimpleWithLogos from "../../components/Header/NavBarSimpleWithLogos"
import BackButton from "../../components/Core/BackButton"
import { useContent } from "../../hooks/useContent"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import useBreakpoint from "../../hooks/useBreakpoint";

const headerConfig = {
    render: false
};

const footerConfig = {
    render: true
};

const feedbackConfig = {
    render: false
}

const Mentorship = ({ data }) => {

    const content = useContent()
    return (
        <>
            <PageWrapper
                headerConfig={headerConfig}
                footerConfig={footerConfig}
                feedbackConfig={feedbackConfig}
            >
                <SEO title="Mentorship" description={content.get('mentorship-intro-subtitle', true)} />
                <NavBarSimpleWithLogos />
                <BackButton />
                <Container className="mb-10">
                    <Row className="align-items-start align-items-xl-center">
                        <Col className="col-12 col-lg-6 order-1 order-lg-0">
                            <h1 className="mb-10">
                                {content.get('mentorship-intro-title', true)}
                            </h1>
                            <div className="font-size-7 font-weight-bold mb-10">
                                <div style={{color:"red"}}>{content.get('mentorship-intro-subtitle', true)}</div>
                            </div>
                            {/* <div className="mb-5">
                                <Link
                                    to="/mentorship/mentor"
                                    className="btn btn-primary mr-8 my-4 text-uppercase w-100 w-sm-auto"
                                    size={['xsm', 'sm', 'md'].includes(useBreakpoint()) ? 'sm' : 'lg'}
                                >
                                    {content.get('mentorship-intro-button-sign-up-mentor', true)}
                                </Link>
                                <Link
                                    to="/mentorship/mentee"
                                    className="btn btn-secondary text-uppercase w-100 w-sm-auto my-4"
                                    size={['xsm', 'sm', 'md'].includes(useBreakpoint()) ? 'sm' : 'lg'}
                                >
                                    {content.get('mentorship-intro-button-sign-up-mentee', true)}
                                </Link>
                            </div> */}
                            {/* <Row className="mb-4">
                                <Col className="col-auto pr-0"><i className="fa fa-heart mr-3 font-size-3"></i></Col>
                                <Col className="pl-0">
                                    <a href="https://cofraholding.qualtrics.com/jfe/form/SV_bq07UOBavV4ERwi" target="_blank"
                                        rel="noopener noreferrer">{content.get('mentorship-intro-button-volunteer', true)}</a>
                                </Col>
                            </Row>
                            <Row>
                                <Col className="col-auto pr-0"><i className="fa fa-info-circle mr-3 font-size-3"></i></Col>
                                <Col className="pl-0">
                                    <Link to="/what-you-need-to-know/mentorship">{content.get('mentorship-intro-button-learn-more', true)}</Link>
                                </Col>
                            </Row> */}
                        </Col>
                        <Col className="order-0 order-lg-1 mb-10">
                            <GatsbyImage
                                imgStyle={{
                                    objectFit: 'contain'
                                }}
                                image={getImage(data.heroImg)}
                                alt={data.name}
                            />
                        </Col>
                    </Row>
                </Container>
            </PageWrapper>
        </>
    )
}

export default Mentorship

export const query = graphql`
    {
        heroImg: file(relativePath: {eq: "photo-two-people-talk.jpg"}) {
            childImageSharp {
                gatsbyImageData (
                    placeholder: BLURRED
                    formats: [AUTO, WEBP],
                    layout: CONSTRAINED
               )
            }
        }
    }
`